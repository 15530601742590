windowReady.ready(function () {
  let data = [
    {
      categoria: "Excavator",
      standard: 350,
      propio: 270,
    },
    {
      categoria: "Dump truck",
      standard: 255.5,
      propio: 293,
    },
    {
      categoria: "Grader",
      standard: 275,
      propio: 187,
    },
    {
      categoria: "Loader",
      standard: 123,
      propio: 223,
    },
  ];

  let width = 1500;
  let height = 600;
  let box = null;

  let svg = null;
  const ejeX = d3.scaleLinear();
  const ejeY = d3.scaleBand();

  function setup() {
    let wrapper = d3.select("#comparacion");
    if (wrapper.node()) {
      crearSVG(wrapper);
      crearEjes();
      crearGrafica();
      crearLeyenda();
    }
  }

  function crearSVG(wrapper) {
    box = new Box({
      size: { width: width, height: height },
      //padding: { top: 10, right: 40, bottom: 90, left: 140 },
      padding: { top: 10, right: 370, bottom: 90, left: 140 },
    });
    width = box.content.width;
    height = box.content.height;
    svg = wrapper
      .append("svg")
      .attr("preserveAspectRatio", "xMidYMid")
      .attr("viewBox", `0 0 ${box.exterior.width} ${box.exterior.height}`);
    svg = svg
      .append("g")
      .attr("transform", `translate(${box.padding.left}, ${box.padding.top})`);
  }

  function crearEjes() {
    ejeX.domain([0, 400]).range([0, width]);
    let gAxisX = svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(ejeX).tickFormat((d) => `${d} L/h`));
    gAxisX.selectAll("text").style("font-size", "24px").attr("fill", "grey");
    gAxisX.selectAll("path").attr("stroke", "grey");
    gAxisX.selectAll("line").attr("stroke", "grey");
    gAxisX
      .append("text")
      .attr("text-anchor", "end")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("x", width + box.padding.right)
      .attr("y", 80)
      .text("Fuel consumption");

    ejeY
      .range([0, height])
      .domain(
        data.map(function (d) {
          return d.categoria;
        })
      )
      .padding(1);
    let gAxisY = svg.append("g").call(d3.axisLeft(ejeY));
    gAxisY.selectAll("text").style("font-size", "24px").attr("fill", "grey");
    gAxisY.selectAll("path").attr("stroke", "grey");
    gAxisY.selectAll("line").attr("stroke", "grey");
  }

  function crearGrafica() {
    let main = svg.append("g");
    main
      .selectAll("line")
      .data(data)
      .join("line")
      .attr("x1", function (d) {
        return ejeX(d.standard);
      })
      .attr("x2", function (d) {
        return ejeX(d.standard);
      })
      .attr("y1", function (d) {
        return ejeY(d.categoria);
      })
      .attr("y2", function (d) {
        return ejeY(d.categoria);
      })
      .attr("stroke", "grey")
      .attr("stroke-width", "1px")
      .transition()
      .duration(600)
      .attr("x2", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.propio")
      .data(data)
      .join("circle")
      .attr("class", "propio")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "6")
      .style("fill", function (d) {
        let color = "#de5b5b";
        if (d.propio <= d.standard) {
          color = "#69b3a2";
        }
        return color;
      })
      .transition()
      .duration(600)
      .attr("cx", function (d) {
        return ejeX(d.propio);
      });

    main
      .selectAll("circle.standard")
      .data(data)
      .join("circle")
      .attr("class", "standard")
      .attr("cx", function (d) {
        return ejeX(d.standard);
      })
      .attr("cy", function (d) {
        return ejeY(d.categoria);
      })
      .attr("r", "6")
      .style("fill", "#4C4082");
  }

  function crearLeyenda() {
    let gLeyenda = svg
      .append("g")
      .attr("transform", `translate(${width}, ${-box.padding.top})`);
    //.attr("id", "gLeyenda");
    /*
    gLeyenda
      .append("rect")
      .attr("fill", "yellow")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", box.padding.right)
      .attr("height", 400);
*/
    gLeyenda
      .append("circle")
      .attr("r", 6)
      .attr("cx", 20)
      .attr("cy", 50)
      .attr("fill", "#69b3a2");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("x", 40)
      .attr("y", 60)
      .text("Below industry standard");

    gLeyenda
      .append("circle")
      .attr("r", 6)
      .attr("cx", 20)
      .attr("cy", 130)
      .attr("fill", "#4C4082");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("x", 40)
      .attr("y", 140)
      .text("Industry standard");

    gLeyenda
      .append("circle")
      .attr("r", 6)
      .attr("cx", 20)
      .attr("cy", 210)
      .attr("fill", "#de5b5b");

    gLeyenda
      .append("text")
      .attr("text-anchor", "start")
      .style("font-size", "30px")
      .attr("fill", "grey")
      .attr("x", 40)
      .attr("y", 220)
      .text("Above industry standard");
  }

  setup();
});
